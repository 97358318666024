export const SEARCH_GRID_ELEMENT_ID = 'interactive-demo-search-grid';
export const SMOOTH_SCROLL_OFFSET = 64;

export const DEFAULT_SEARCH_LABEL = 'Search';
export const NO_SEARCH_RESULTS_MESSAGE = 'Sorry! no result found :(';
export const CLEAR_ALL_LABEL = 'Clear All';
export const DEFAULT_FILTER_OPERATOR = 'or';

export const ON_DEMAND_LABEL = 'ON-DEMAND';
export const UPCOMING_LABEL = 'UPCOMING';
export const DATE_FILTER_LABEL = 'Categories';

export const EVENT_STATE = {
  ALL: 'ALL',
  ON_DEMAND: 'ON-DEMAND',
  UPCOMING: 'UPCOMING',
} as const;

import React from 'react';
import { cn } from '@/lib/tailwind';
import Icon from '../Icon';
import { Props } from './types';

const SearchBox = ({
  containerClassName,
  iconClassName,
  className,
  ...props
}: Props) => {
  return (
    <label
      htmlFor="search-input"
      className={cn(
        'flex justify-between items-center gap-2 bg-white px-[27px] py-[17px] rounded-full cursor-text',
        'border-[0.5px] border-solid border-black focus-within:border-primaryOceanBlue transition-border group',
        containerClassName,
      )}
    >
      <input
        id="search-input"
        {...props}
        className={cn(
          'outline-none w-full text-base leading-[120%] placeholder:text-baseBlack/50 bg-white',
          className,
        )}
        type="search"
      />
      <Icon
        icon="searchV2"
        className={cn(
          'fill-current h-4 w-4 group-focus-within:text-primaryOceanBlue',
          iconClassName,
        )}
      />
    </label>
  );
};

export default SearchBox;

const scrollToElement = (
  elementId: string,
  headerHeight: number,
  scrollOffset: number,
) => {
  const resultGridElement = document.getElementById(elementId);
  if (resultGridElement) {
    const elementTop = resultGridElement.getBoundingClientRect().top;
    const offsetPosition =
      elementTop + window.scrollY - headerHeight - scrollOffset;
    window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
  }
};

export default scrollToElement;

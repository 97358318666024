const PRODUCT_OVERVIEW_PAGE_SLUG = /^\/products\/([^/]+)\/$/;
const PRODUCT_FEATURE_PAGE_SLUG =
  /^\/products\/([^/]+)\/([^/]+)(?:\/([^/]+))?\/$/;

export const getPathSegmentsFromFeaturePageSlug = (
  slug: string,
): [string, string[]] => {
  const [_, product, ...feature] = slug.match(PRODUCT_FEATURE_PAGE_SLUG) ?? [];
  return [product, feature.filter(o => Boolean(o))];
};

export const getProductFromProductOverviewPageSlug = (slug: string) => {
  const [_, product] = slug.match(/\/products\/([^/]+)/) ?? [];
  return product;
};

export const isProductSlug = (slug: string) => {
  return PRODUCT_OVERVIEW_PAGE_SLUG.test(slug);
};

export const isFeatureSlug = (slug: string) => {
  return PRODUCT_FEATURE_PAGE_SLUG.test(slug);
};

export const getFeaturePageSlug = ({
  product,
  feature,
}: {
  product: string;
  feature: string[];
}) => {
  return `/products/${product}/${feature.join('/')}/`;
};

export const getProductOverviewPageSlug = ({
  product,
}: {
  product: string;
}) => {
  return `/products/${product}/`;
};

'use client';
import { useRefinementList } from 'react-instantsearch';
import { useCallback } from 'react';
import FilterCheckbox from './FilterCheckBox';

type RefinementListItem = ReturnType<typeof useRefinementList>['items'][0];
export const AlgoliaFilterOption = ({
  item,
  onClick,
}: {
  item: RefinementListItem;
  onClick: (label: string) => void;
}) => {
  const handleCheckedChange = useCallback(
    () => onClick(item.label),
    [onClick, item],
  );
  return (
    <FilterCheckbox
      checked={item.isRefined}
      label={item.label}
      onChange={handleCheckedChange}
    />
  );
};

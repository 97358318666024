import React, { Fragment } from 'react';
import { cn } from '@/lib/tailwind';
import Icon from '../Icon';
import BreadcrumbItem from './BreadCrumbItem';

import { Crumb } from './types';

const ARROW_SEPARATOR = (
  <Icon icon="arrowTriangle" className="inline-block h-2 fill-black" />
);

export const Breadcrumb = ({
  items,
  className,
}: {
  items: Crumb[];
  className?: string;
}) => {
  return (
    <div
      className={cn(
        'rounded-full bg-secondaryBlanc py-2.5 px-4 inline-flex gap-4 items-center max-desktop-sm:hidden',
        className,
      )}
    >
      {items.map((item, index) => {
        return (
          <Fragment key={item.label}>
            {index > 0 ? ARROW_SEPARATOR : null}
            <BreadcrumbItem {...item} />
          </Fragment>
        );
      })}
    </div>
  );
};

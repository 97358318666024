'use client';
import dynamic from 'next/dynamic';

import { ProductCustomerStoryProps } from './types';

export default dynamic<ProductCustomerStoryProps>(
  () =>
    import(
      /* webpackChunkName: "productCustomerStories" */ './ProductCustomerStories'
    ),
);

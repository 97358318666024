'use client';

import React, { ChangeEvent, useCallback } from 'react';
import { useSearchBox } from 'react-instantsearch';
import { useTranslations } from 'next-intl';
import { cn } from '@/lib/tailwind';
import { SearchBox } from '@/components/searchbox';
import { DEFAULT_SEARCH_LABEL } from '../constants';

const SearchBoxWrapper = () => {
  const t = useTranslations();
  const { refine } = useSearchBox();
  const handleInputChange = useCallback<
    (event: ChangeEvent<HTMLInputElement>) => void
  >(
    e => {
      refine(e.target.value);
    },
    [refine],
  );

  return (
    <div className="mb-8 tablet-sm:mb-6 desktop-sm:mb-14 desktop-lg:mb-16">
      <SearchBox
        placeholder={t(DEFAULT_SEARCH_LABEL)}
        onChange={handleInputChange}
        iconClassName={
          'h-[18px] tablet-sm:h-[22px] desktop-sm:h-7 w-auto fill-mediumGrey group-focus-within:fill-primaryOceanBlue group-hover:fill-primaryOceanBlue'
        }
        className={cn(
          'block w-full line-clamp-1 text-ellipsis focus:outline-none focus:border-primaryOceanBlue transition-border',
          'text-black placeholder:text-cometBlack',
          'text-mobile-sm/body-2-bold tablet-sm:text-tablet-sm/body-1-bold desktop-lg:text-desktop-large/body-1-bold',
          'placeholder:text-mobile-sm/body-3 tablet-sm:placeholder:text-tablet-sm/body-2 desktop-sm:placeholder:text-tablet-sm/body-1 desktop-lg:placeholder:text-desktop-large/body-1',
        )}
        containerClassName={cn(
          'flex-row-reverse border-[1px] border-mediumGrey',
          'py-2.5 px-4 gap-4 tablet-sm:py-4 tablet-sm:px-6 desktop-sm:gap-6 desktop-lg:py-5',
          'hover:border-primaryOceanBlue',
        )}
      />
    </div>
  );
};

export default SearchBoxWrapper;

import React, { ComponentPropsWithoutRef, useCallback } from 'react';
import { PaginationItem } from '@/components/ui/pagination';

export const PaginationButton = ({
  value,
  variant,
  disabled,
  children,
  onClick,
  ...props
}: Omit<ComponentPropsWithoutRef<typeof PaginationItem>, 'onClick'> & {
  value: number;
  onClick: (value: number) => void;
}) => {
  const handleClick = useCallback(() => {
    onClick(value);
  }, [value, onClick]);

  return (
    <PaginationItem
      {...props}
      onClick={handleClick}
      disabled={disabled}
      variant={variant}
    >
      {children}
    </PaginationItem>
  );
};

'use client';
import {
  createContext,
  ReactNode,
  useContext,
  useState,
  useCallback,
  useMemo,
} from 'react';

type BlogListingContext = {
  activeCategories: Array<string>;
  allCategories: Array<string>;
  handleActiveCategories: (categories: Array<string>) => void;
};

const BlogListingContext = createContext<BlogListingContext>({
  activeCategories: [],
  allCategories: [],
  handleActiveCategories: () => null,
});

export const useBlogListingContext = () => useContext(BlogListingContext);

const BlogListingContextProvider = ({
  children,
  allCategories,
}: {
  children: ReactNode;
  allCategories: Array<string>;
}) => {
  const [activeCategories, setActiveCategories] = useState(allCategories);

  const handleActiveCategories = useCallback((categories: Array<string>) => {
    setActiveCategories(categories);
  }, []);

  const contextValue = useMemo(
    () => ({
      activeCategories,
      allCategories,
      handleActiveCategories,
    }),
    [activeCategories, allCategories, handleActiveCategories],
  );

  return (
    <BlogListingContext.Provider value={contextValue}>
      {children}
    </BlogListingContext.Provider>
  );
};

export default BlogListingContextProvider;

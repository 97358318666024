import { CtaData, ImageData } from '@/types';

export enum LabelVariant {
  SpaceGrey = 'SPACE_GREY',
  CreamsicleYellow = 'CREAMSICLE_YELLOW',
}

export interface ResourceGridItemProps {
  title: string;
  label?: string;
  description: string;
  cta: CtaData;
  asset: ImageData;
}

'use client';

import React, { ReactNode, useCallback, useState } from 'react';
import dynamic from 'next/dynamic';

import { Item, ItemAndElement } from '../stickySubNavWrapper/types';
import SubNavRefComponent from './components/SubNavRefComponent';

import { FloatingNavbarItemType } from './types';

const SubNav = dynamic(
  () => import('../stickySubNavWrapper/components/subNav'),
);

const FloatingNavbarClient = ({
  items,
}: {
  items: FloatingNavbarItemType<ReactNode>[];
}) => {
  const [list, setList] = useState<Array<ItemAndElement>>([]);

  const onAttach = useCallback((itemAndElement: ItemAndElement) => {
    setList(prev => {
      return [...prev, itemAndElement];
    });
  }, []);

  const onDetach = useCallback(({ item }: { item: Item }) => {
    setList(prev => {
      const list = [...prev].filter(
        o => o.item.categoryLabel !== item.categoryLabel,
      );
      return list;
    });
  }, []);

  return (
    <div className="relative">
      <SubNav list={list} enableFloatingBehavior>
        {items.map(item => (
          <SubNavRefComponent
            key={item.id}
            onAttach={onAttach}
            onDetach={onDetach}
            categoryLabel={item.title}
            id={item.id}
          >
            {item.widget}
          </SubNavRefComponent>
        ))}
      </SubNav>
    </div>
  );
};

export default FloatingNavbarClient;

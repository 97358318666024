import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/tailwind';

const badgeVariants = cva(
  'inline-flex items-center w-fit rounded-[100px] px-3 py-1 text-mobile-sm/label-2 transition-colors uppercase focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 tablet-sm:text-tablet-sm/label-1 desktop-lg:text-desktop-large/label-1',
  {
    variants: {
      variant: {
        default:
          'bg-black text-white px-5 py-2 text-desktop/subhead-xs tablet:text-desktop/subhead-s rounded-full',
        highlight: 'bg-highlight text-black',
        spaceGrey: 'bg-primarySpaceGrey text-white',
        disabled: 'bg-spaceGreyLight text-mediumGrey',
        indicatorGrey: 'bg-indicatorGrey text-white',
        earthGreen: 'bg-earthGreen text-white',
        goldenOrange: 'bg-goldenOrange text-white',
        twilightLavender: 'bg-twilightLavender text-black',
        lightCyan:
          'bg-lightCyan border border-primarySkyBlue py-[3px] text-black',
        refreshSkyline: 'bg-refreshSkyline text-white',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export type BadgeVariant = VariantProps<typeof badgeVariants>['variant'];

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };

import React from 'react';

type Props = {
  renderIf: boolean | string | number | null | undefined;
  children: React.ReactNode;
};

const Conditional = ({ renderIf, children }: Props) => {
  return renderIf ? <>{children}</> : null;
};

export default Conditional;

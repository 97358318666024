'use client';
import { Slot } from '@radix-ui/react-slot';
import {
  CSSProperties,
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

const SubNavHeightContext = createContext<[number, (height: number) => void]>([
  0,
  () => null,
]);

export const SubNavHeightContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [subNavHeight, setSubNavHeight] = useState(0);
  return (
    <SubNavHeightContext.Provider value={[subNavHeight, setSubNavHeight]}>
      {children}
    </SubNavHeightContext.Provider>
  );
};

export const SubNavHeightListener = ({
  style,
  ...props
}: {
  children: ReactNode;
  style?: CSSProperties;
}) => {
  const [subNavHeight] = useContext(SubNavHeightContext);
  const containerStyles = useMemo(
    () => ({
      ...style,
      ['--subnav-height' as string]: `${subNavHeight}px`,
    }),
    [subNavHeight, style],
  );

  return <Slot style={containerStyles} {...props} />;
};

export default SubNavHeightContext;

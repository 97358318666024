'use client';

import React, { ReactNode, useCallback, useMemo } from 'react';
import { getFragmentFromLabel } from '../../stickySubNavWrapper/utils';

import { Item, ItemAndElement } from '../../stickySubNavWrapper/types';

type Props = {
  id: string;
  categoryLabel: string;
  children: ReactNode;
  onAttach: (arg0: ItemAndElement) => void;
  onDetach: (arg0: { item: Item }) => void;
};

const SubNavRefComponent = ({
  children,
  categoryLabel,
  id,
  onAttach,
  onDetach,
}: Props) => {
  const item = useMemo(
    () => ({ categoryLabel, contentType: 'widgetProductSection', id }),
    [id, categoryLabel],
  );

  const attachRef = useCallback(
    (el: HTMLDivElement) => {
      if (el) {
        onAttach({
          item,
          el,
        });
      } else {
        onDetach({
          item,
        });
      }
    },
    [onAttach, onDetach, item],
  );

  return (
    <section
      data-category-label={getFragmentFromLabel(categoryLabel)}
      ref={attachRef}
      className="relative"
    >
      <div
        id={getFragmentFromLabel(categoryLabel)}
        className="absolute top-[--subnav-offset]"
      />
      {children}
    </section>
  );
};

export default SubNavRefComponent;

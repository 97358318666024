import { ReactElement } from 'react';

/**
 * Inserts banners into a list of children at calculated positions.
 *
 * - 1 banner: placed in the middle.
 * - 2 banners: placed at positions dividing children into thirds.
 *
 * @param {ReactElement[]} children - Array of child elements.
 * @param {ReactElement[]} banners - Array of banner elements.
 * @returns {ReactElement[]} New array with banners inserted.
 *
 */
const placeBanners = (
  children: ReactElement[],
  banners: ReactElement[],
): ReactElement[] => {
  const childrenLength = children.length;
  const bannerCount = banners.length;

  const childrenWithBanners = [...children];

  if (bannerCount === 1) {
    const middlePosition = Math.floor(childrenLength / 2);
    childrenWithBanners.splice(middlePosition, 0, banners[0]);
  } else if (bannerCount === 2) {
    const firstBannerPosition = Math.ceil((childrenLength + 1) / 3);
    const secondBannerPosition = Math.floor((2 * childrenLength + 3) / 3);

    childrenWithBanners.splice(firstBannerPosition, 0, banners[0]);
    childrenWithBanners.splice(secondBannerPosition + 1, 0, banners[1]);
  }

  return childrenWithBanners;
};

export default placeBanners;

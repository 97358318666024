'use client';
import React, { useCallback, useState } from 'react';
import ImageBlock from '@/components/ImageBlock';
import { CurveSectionWithGradientShadow } from '@/components/CurveSectionWithGradientShadow';
import { cn } from '@/lib/tailwind';
import { Props } from '../types';

const CurvedImageContainer = ({
  isGradientShown,
  backgroundImage,
  isOverlayEnabled = false,
}: Pick<Props, 'backgroundImage' | 'isGradientShown' | 'isOverlayEnabled'>) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const handleImageLoad = useCallback(
    () => setIsImageLoaded(true),
    [setIsImageLoaded],
  );
  return (
    <div className="absolute h-full w-full top-1/2 start-1/2 -translate-x-1/2 -translate-y-1/2 z-10 transform-gpu">
      <CurveSectionWithGradientShadow
        isShadowEnabled={isImageLoaded}
        isGradientShown={isGradientShown && isImageLoaded}
        isGradientTransitionDelayed={false}
      >
        <div
          className={cn(
            'h-full w-full relative',
            isOverlayEnabled ? 'bg-darkOverlayGradient' : '',
          )}
        >
          <ImageBlock
            image={backgroundImage}
            fill
            priority
            className="-z-20 object-cover brightness-50"
            quality={50}
            onLoad={handleImageLoad}
          />
        </div>
      </CurveSectionWithGradientShadow>
    </div>
  );
};

export default CurvedImageContainer;

'use client';
import {
  ComponentProps,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDynamicWidgets } from 'react-instantsearch';
import { useClickAway } from 'react-use';
import { cn } from '@/lib/tailwind';
import { Accordion } from '@/components/ui/accordion';
import Conditional from '@/components/Conditional';
import useResponsiveValues from '@/hooks/useResponsiveValues';
import { HeaderHeightListener } from '@/contexts/HeaderHeightContext';
import { DATE_FILTER_LABEL } from '../constants';
import { Props } from '../types';
import { FilterClearButton } from './FilterClearButton';
import { FilterTitle } from './FilterTitle';
import { FilterMenu } from './FilterMenu';
import CustomDateFilterMenu from './CustomDateFilterMenu';

const ACCORDION_CLASSNAMES = cn(
  'col-span-2 desktop-sm:col-span-1',
  'mt-4 desktop-sm:mt-0',
  'flex flex-col tablet-sm:flex-row desktop-sm:flex-col gap-y-4 tablet-sm:gap-x-2',
);
const FilterSection = ({
  enableDateFiltering,
}: Pick<Props, 'enableDateFiltering'>) => {
  const accordionType: ComponentProps<typeof Accordion>['type'] =
    useResponsiveValues(['single', null, 'multiple']);

  const { attributesToRender: filters } = useDynamicWidgets();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [singleAccordionValue, setSingleAccordionValue] = useState<string>('');
  const [multipleAccordionValue, setMultipleAccordionValue] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (enableDateFiltering)
      setMultipleAccordionValue([DATE_FILTER_LABEL, ...filters]);
    else setMultipleAccordionValue(filters);
  }, [filters, enableDateFiltering]);

  useClickAway(containerRef, () => {
    if (accordionType === 'single') {
      setSingleAccordionValue('');
    }
  });

  const handleAccordionValueChange = useCallback((value: string) => {
    setSingleAccordionValue(value);
  }, []);

  return (
    <HeaderHeightListener>
      <div
        className={cn(
          'desktop-sm:w-[190px] desktop-lg:w-[256px] desktop-sm:self-start',
          'grid grid-cols-2 desktop-sm:grid-cols-1 desktop-sm:gap-y-4',
          'desktop-sm:sticky desktop-sm:top-[calc(var(--header-height)_+_20px)]',
        )}
      >
        <FilterTitle />
        <FilterClearButton />
        {accordionType === 'single' ? (
          <Accordion
            ref={containerRef}
            type={accordionType}
            value={singleAccordionValue}
            onValueChange={handleAccordionValueChange}
            collapsible
            className={ACCORDION_CLASSNAMES}
          >
            <Conditional renderIf={enableDateFiltering}>
              <CustomDateFilterMenu name={DATE_FILTER_LABEL} />
            </Conditional>
            {filters.map(filter => (
              <FilterMenu
                key={filter}
                name={filter}
                isExpanded={singleAccordionValue === filter}
              />
            ))}
          </Accordion>
        ) : (
          <Accordion
            type={accordionType}
            className={ACCORDION_CLASSNAMES}
            value={multipleAccordionValue}
            onValueChange={setMultipleAccordionValue}
          >
            <Conditional renderIf={enableDateFiltering}>
              <CustomDateFilterMenu name={DATE_FILTER_LABEL} />
            </Conditional>
            {filters.map(filter => (
              <FilterMenu
                key={filter}
                name={filter}
                isExpanded={multipleAccordionValue.includes(filter)}
              />
            ))}
          </Accordion>
        )}
      </div>
    </HeaderHeightListener>
  );
};

export default FilterSection;

import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/tailwind';

const buttonVariants = cva('spr-btn', {
  variants: {
    variant: {
      bare: 'block rounded-none',
      primary: 'spr-btn-primary',
      secondary: 'spr-btn-secondary',
      light: 'border-primaryOceanBlue bg-white',
      disabled: 'bg-mediumGrey text-white fill-white',
      link: 'text-black underline-offset-4 hover:underline uppercase font-semibold',
    },
    size: {
      default: 'spr-btn-default',
      sm: 'py-1.5 px-5 text-[12px] font-semibold',
      icon: 'h-10 w-10',
      block: 'p-2 rounded-[3px] w-8 h-8',
      bare: '',
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'default',
  },
});

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };

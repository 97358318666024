'use client';
import { ReactNode, Suspense } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

const isWidgetErrorBoundaryEnabled =
  process.env.NEXT_PUBLIC_DISABLE_SSG === 'true';

const WidgetErrorBoundary = ({
  widgetId,
  widgetName,
  children,
}: {
  widgetId: string;
  widgetName: string;
  children: ReactNode;
}) => {
  const fallbackRender = ({ error }: FallbackProps): ReactNode => {
    return (
      <div className="w-full bg-red-800 p-10 text-whiteSmoke text-md">
        <p>Error Occurred!</p>
        <p>Widget Id: {widgetId}</p>
        <p>Widget Name: {widgetName}</p>
        <p>
          Error:
          <pre className="border border-black bg-slate p-2 mt-1 whitespace-pre-wrap">{`${error}`}</pre>
        </p>
      </div>
    );
  };

  if (!isWidgetErrorBoundaryEnabled) {
    return children;
  }

  return (
    <ErrorBoundary fallbackRender={fallbackRender}>
      <Suspense>{children}</Suspense>
    </ErrorBoundary>
  );
};

export default WidgetErrorBoundary;

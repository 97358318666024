'use client';
import _throttle from 'lodash.throttle';
import { MutableRefObject, ReactNode, useLayoutEffect, useRef } from 'react';

const THROTTLE_DELAY = 80;

const onScroll = (ref: MutableRefObject<HTMLDivElement | null>) => () => {
  ref.current?.style.setProperty('--scroll', `${window.scrollY}`);
};
const ScrollListener = ({
  children,
  enableThrottling = false,
}: {
  children: ReactNode;
  enableThrottling?: boolean;
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  useLayoutEffect(() => {
    const handleScroll = enableThrottling
      ? _throttle(onScroll(containerRef), THROTTLE_DELAY)
      : onScroll(containerRef);

    handleScroll();

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [enableThrottling]);
  return <div ref={containerRef}>{children}</div>;
};

export default ScrollListener;

export function generateEmailShareLink({
  link,
  linkTitle,
}: {
  link: string;
  linkTitle: string;
}): string {
  return `mailto:?subject=Sprinklr.com:${encodeURIComponent(
    linkTitle,
  )}&body=${encodeURIComponent(link)}`;
}

export function generateLinkedInShareLink({
  link,
  linkTitle,
}: {
  link: string;
  linkTitle: string;
}): string {
  const shareLink = new URL('https://www.linkedin.com/shareArticle');
  shareLink.searchParams.append('title', linkTitle);
  shareLink.searchParams.append('url', link);
  return shareLink.toString();
}

export function generateTwitterShareLink({
  link,
  linkTitle,
}: {
  link: string;
  linkTitle: string;
}): string {
  const shareLink = new URL('https://twitter.com/intent/tweet');
  shareLink.searchParams.append('text', linkTitle);
  shareLink.searchParams.append('url', link);
  return shareLink.toString();
}

export function generateFacebookShareLink({ link }: { link: string }): string {
  const shareLink = new URL('https://www.facebook.com/sharer.php');
  shareLink.searchParams.append('u', link);
  return shareLink.toString();
}

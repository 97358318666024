import { Checkbox } from '@/components/ui/checkbox';
import { cn } from '@/lib/tailwind';
import { FilterCheckboxData } from '../types';

const FilterCheckbox = ({ label, checked, onChange }: FilterCheckboxData) => (
  <div className="flex items-center">
    <Checkbox
      id={label}
      checked={checked}
      onCheckedChange={onChange}
      className={cn(
        'w-2.5 h-2.5 desktop-sm:w-4 desktop-sm:h-4',
        'rounded-none border',
        'data-[state=checked]:bg-primaryOceanBlue data-[state=checked]:border-primaryOceanBlue',
      )}
    />
    <label htmlFor={label} className="ms-2 cursor-pointer">
      {label}
    </label>
  </div>
);

export default FilterCheckbox;

import React from 'react';
import { Highlight, useHits, useSearchBox } from 'react-instantsearch';
import { cn } from '@/lib/tailwind';
import LibraryCard from '@/components/libraryCard';
import NoResultsBoundary from '@/components/algolia/NoResultsBoundary';
import NoResultsFallback from '@/components/algolia/NoResultsFallback';
import { LibraryCardData } from '@/components/libraryCard/types';
import Pagination from '@/components/pagination';
import { BadgeVariant } from '@/components/ui/badge';
import {
  ON_DEMAND_LABEL,
  SEARCH_GRID_ELEMENT_ID,
  SMOOTH_SCROLL_OFFSET,
  UPCOMING_LABEL,
} from '../constants';
import { Props } from '../types';
import { Loader } from './Loader';

const getLabelData = (
  eventDateTimestamp: number,
): { label?: string; labelVariant: BadgeVariant } => {
  const currentTimestamp = Math.floor(new Date().getTime() / 1000);
  return eventDateTimestamp > currentTimestamp
    ? { label: UPCOMING_LABEL, labelVariant: 'lightCyan' }
    : { label: ON_DEMAND_LABEL, labelVariant: 'twilightLavender' };
};

const LibraryCardGrid = ({
  enableDateFiltering,
  searchFallbackContent,
}: Pick<Props, 'enableDateFiltering' | 'searchFallbackContent'>) => {
  const { hits } = useHits<LibraryCardData & { eventDate: number }>();
  const { query } = useSearchBox();

  return (
    <div className="flex-1 flex flex-col">
      <NoResultsBoundary
        fallback={
          <NoResultsFallback {...searchFallbackContent} queryString={query} />
        }
        loader={<Loader />}
      >
        <div
          className={cn(
            'flex-1 grid grid-cols-1 tablet-sm:grid-cols-2 auto-rows-fr',
            'gap-6 tablet-sm:gap-x-7 tablet-sm:gap-y-8 desktop-sm:gap-10 desktop-lg:gap-x-12',
          )}
        >
          {hits.map(hit => {
            const title = <Highlight hit={hit} attribute="title" />;
            const labelData = enableDateFiltering
              ? getLabelData(hit.eventDate)
              : { label: undefined, labelVariant: undefined };
            return (
              <LibraryCard
                key={hit.objectID}
                title={title}
                description={hit.description}
                image={hit.image}
                label={labelData?.label}
                labelVariant={labelData?.labelVariant}
                cta={hit.cta}
                id={hit.objectID}
                isPlayIconEnabled={hit.isPlayIconEnabled}
              />
            );
          })}
        </div>
      </NoResultsBoundary>
      <Pagination
        gridContainerId={SEARCH_GRID_ELEMENT_ID}
        paginationScrollOffset={SMOOTH_SCROLL_OFFSET}
        className="mt-8 tablet-sm:mt-20"
      />
    </div>
  );
};

export default LibraryCardGrid;

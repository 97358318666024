'use client';
import dynamic from 'next/dynamic';

import { Props } from './types';

export default dynamic<Props>(
  () => import(/* webpackChunkName: "formWidget" */ './FormWidget'),
);

export type { Props };

import React, { useCallback, useContext } from 'react';
import { usePagination } from 'react-instantsearch';
import {
  Pagination,
  PaginationContent,
  PaginationNext,
  PaginationPrevious,
} from '@/components/ui/pagination';

import HeaderHeightContext from '@/contexts/HeaderHeightContext';
import scrollToElement from '@/utils/scrollToElement';
import { PaginationButton } from '../PaginationButton';

const PaginationWrapper = ({
  className,
  gridContainerId,
  paginationScrollOffset,
}: {
  className?: string;
  gridContainerId: string;
  paginationScrollOffset: number;
}) => {
  const [headerHeight] = useContext(HeaderHeightContext);

  const { pages, currentRefinement, isFirstPage, isLastPage, refine } =
    usePagination({
      padding: 1,
    });

  const previousPageIndex = currentRefinement - 1;
  const nextPageIndex = currentRefinement + 1;

  const handlePaginationButtonClick = useCallback(
    (value: number) => {
      refine(value);
      scrollToElement(gridContainerId, headerHeight, paginationScrollOffset);
    },
    [refine, gridContainerId, headerHeight, paginationScrollOffset],
  );

  return pages.length > 1 ? (
    <Pagination className={className}>
      <PaginationContent>
        <PaginationButton
          value={previousPageIndex}
          variant={isFirstPage ? 'disabled' : 'highlighted'}
          disabled={isFirstPage}
          onClick={handlePaginationButtonClick}
        >
          <PaginationPrevious />
        </PaginationButton>

        {pages.map(page => (
          <PaginationButton
            key={page}
            value={page}
            onClick={handlePaginationButtonClick}
            variant={page === currentRefinement ? 'active' : 'default'}
          >
            <span
              key={page}
              className="text-mobile-sm/body-3 tablet-sm:text-tablet-sm/body-1 desktop-lg:text-desktop-large/body-1"
            >
              {page + 1}
            </span>
          </PaginationButton>
        ))}

        <PaginationButton
          value={nextPageIndex}
          variant={isLastPage ? 'disabled' : 'highlighted'}
          disabled={isLastPage}
          onClick={handlePaginationButtonClick}
        >
          <PaginationNext />
        </PaginationButton>
      </PaginationContent>
    </Pagination>
  ) : null;
};
export default PaginationWrapper;

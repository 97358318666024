'use client';

import React, {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useReducer,
} from 'react';
import { GlossaryItemType } from '@/types/glossary';
import { LISTING_MODE } from '../constants';
import {
  ListingMode,
  ListingData,
  ListingAction,
  ListingDataState,
} from '../types';

type GlossaryContextValue = {
  activeCategory?: string;
  listingMode: ListingMode;
  listingData?: ListingData;
  categorizedTerms: Map<string, GlossaryItemType[]>;
  dispatch: Dispatch<ListingAction>;
};

const GlossaryContext = createContext<GlossaryContextValue>({
  activeCategory: '',
  listingData: [],
  listingMode: LISTING_MODE.ALL,
  categorizedTerms: new Map(),
  dispatch: () => null,
});

export const useGlossaryContext = () => useContext(GlossaryContext);

type GlossaryContextProviderProps = {
  categorizedTerms: Map<string, GlossaryItemType[]>;
  children: ReactNode;
};

const GlossaryContextProvider = ({
  children,
  categorizedTerms,
}: GlossaryContextProviderProps) => {
  const listingReducer = (state: ListingDataState, action: ListingAction) => {
    const payload = {
      listingMode: action.type,
    };
    switch (action.type) {
      case LISTING_MODE.ALL:
        return {
          ...payload,
          activeCategory: '',
        };
      case LISTING_MODE.SINGLE:
        return {
          ...payload,
          activeCategory: action.activeCategory || '',
        };
      case LISTING_MODE.SEARCH:
        return {
          ...payload,
          listingData: action.listingData || [],
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(listingReducer, {
    listingMode: LISTING_MODE.ALL,
  });

  const glossaryContextValue = {
    ...state,
    categorizedTerms,
    dispatch,
  };

  return (
    <GlossaryContext.Provider value={glossaryContextValue}>
      {children}
    </GlossaryContext.Provider>
  );
};

export default GlossaryContextProvider;

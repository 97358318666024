'use client';
import { useState } from 'react';
import { cn } from '@/lib/tailwind';
import { Link } from '@/components/Link';
import Text from '@/components/text';
import CallToAction from '@/components/CallToAction';
import { Badge } from '@/components/ui/badge';
import ImageBlock from '../../ImageBlock';
import { LabelVariant, ResourceGridItemProps } from '../types';

const ResourceGridItem = ({
  title,
  label,
  cta,
  asset,
  labelVariant,
}: ResourceGridItemProps & { labelVariant: LabelVariant }) => {
  const [isImageHovered, setIsImageHovered] = useState(false);
  return (
    <div className="flex flex-col desktop-sm:flex-row gap-x-10">
      <Link
        href={cta.url}
        className={cn(
          'w-full desktop-sm:max-w-[164px] rounded-sm-alt overflow-hidden',
          ' h-[220px] tablet-sm:h-[240px] desktop-sm:h-[146px]',
        )}
        onMouseEnter={() => setIsImageHovered(true)}
        onMouseLeave={() => setIsImageHovered(false)}
      >
        <ImageBlock
          image={asset}
          className={cn(
            'object-cover w-full h-full',
            'desktop-sm:transform desktop-sm:hover:scale-110 desktop-sm:transition-transform desktop-sm:ease-in-out desktop-sm:duration-300',
          )}
        />
      </Link>
      <div className="flex-1 flex flex-col max-tablet-sm:justify-between justify-start h-full">
        {label ? (
          <Badge
            className="mt-4 tablet-sm:mt-6 desktop-sm:mt-0"
            variant={
              labelVariant === LabelVariant.CreamsicleYellow
                ? 'highlight'
                : 'spaceGrey'
            }
          >
            {label}
          </Badge>
        ) : null}
        <div className="flex flex-col flex-1 justify-between">
          {/* TODO: limit no of characters from CMS */}
          <Link href={cta.url}>
            <Text
              as="p"
              className={cn(
                'text-mobile-sm/body-3-bold tablet-sm:text-tablet-sm/heading-5 desktop-lg:text-desktop-large/heading-5 hover:underline hover:text-primaryOceanBlue',
                'mt-4 desktop-lg:mt-5 line-clamp-3',
                isImageHovered ? 'underline text-primaryOceanBlue' : '',
              )}
            >
              {title}
            </Text>
          </Link>
          <CallToAction
            {...cta}
            className="mt-4 cursor-pointer hover:underline hover:text-primaryOceanBlue"
          />
        </div>
      </div>
    </div>
  );
};

export default ResourceGridItem;

'use client';
import React, { ReactNode } from 'react';
import { useMeasure } from 'react-use';
import { cn } from '@/lib/tailwind';

const GRADIENT_SHADOW_TRANSITION_CLASSNAMES =
  'transition-[opacity,filter] duration-800 ease-in';
const MOBILE_CURVE_HEIGHT = 15;
const TABLET_SMALL_CURVE_HEIGHT = 91;
const DESKTOP_SMALL_CURVE_HEIGHT = 156;
const DESKTOP_LARGE_CURVE_HEIGHT = 204;

export const CurveSectionWithGradientShadow = ({
  isShadowEnabled,
  children,
  isGradientShown = true,
  isGradientTransitionDelayed = true,
}: {
  isShadowEnabled: boolean;
  children: ReactNode;
  isGradientShown?: boolean;
  isGradientTransitionDelayed?: boolean;
}) => {
  const [ref, { width, height: containerHeight }] =
    useMeasure<HTMLDivElement>();

  const gradientTranstionClassnames = cn(
    GRADIENT_SHADOW_TRANSITION_CLASSNAMES,
    isGradientTransitionDelayed ? '[transition-delay:1500ms]' : '',
  );

  return (
    <div className="relative w-full h-full">
      <svg
        width="0"
        height="0"
        className={`[--curve-height:var(--mobile-curve-height)] tablet-sm:[--curve-height:var(--tablet-sm-curve-height)] 
        desktop-sm:[--curve-height:var(--desktop-small-curve-height)] desktop-lg:[--curve-height:var(--desktop-large-curve-height)]`}
        style={{
          ['--width' as string]: width,
          ['--container-height' as string]: containerHeight,
          ['--mobile-curve-height' as string]: MOBILE_CURVE_HEIGHT,
          ['--tablet-sm-curve-height' as string]: TABLET_SMALL_CURVE_HEIGHT,
          ['--desktop-small-curve-height' as string]:
            DESKTOP_SMALL_CURVE_HEIGHT,
          ['--desktop-large-curve-height' as string]:
            DESKTOP_LARGE_CURVE_HEIGHT,
          ['--4-h-sq' as string]:
            'calc(4px * var(--curve-height) * var(--curve-height))',
          ['--l-sq' as string]: 'calc(1px * var(--width) * var(--width))',
          ['--radius' as string]:
            'calc((var(--4-h-sq) + var(--l-sq)) / (8 * var(--curve-height)))',
        }}
      >
        <defs>
          <clipPath id="curved-bottom">
            <rect
              x={0}
              y={0}
              width={width}
              style={{
                height:
                  'max(calc((var(--container-height) - var(--curve-height)) * 1px), 0px)',
              }}
            />
            <circle
              cx={width / 2}
              className="[cy:calc((var(--container-height)_*_1px)_-_var(--radius))] [r:var(--radius)]"
            />
          </clipPath>
        </defs>
      </svg>
      {isGradientShown ? (
        <div className="absolute -bottom-1/2 w-full h-full overflow-hidden translate-y-14 -z-40">
          <div
            className={cn(
              'absolute -z-10 aspect-[2] rounded-full bg-primaryEarthGreen blur-[50px]',
              'bottom-[55%] left-[5%] w-1/2 desktop-sm:left-0 desktop-sm:w-[60%]',
              gradientTranstionClassnames,
              isShadowEnabled ? 'opacity-100' : 'opacity-0',
            )}
          />
          <div
            className={cn(
              'absolute -z-10 aspect-[2] rounded-full bg-primarySkyBlue blur-[50px]',
              'bottom-[55%] left-[45%] w-1/2 desktop-sm:right-0 desktop-sm:w-[60%]',
              gradientTranstionClassnames,
              isShadowEnabled ? 'opacity-100' : 'opacity-0',
            )}
          />
          <div
            className={cn(
              'absolute -z-10 aspect-[2] rounded-full bg-primarySunOrange',
              'bottom-[58%] left-[-10%] w-1/5 desktop-sm:bottom-[68%] desktop-sm:left-[-15%] desktop-sm:w-[25%]',
              'blur-[20px] tablet-sm:blur-[60px] desktop-sm:blur-[100px]',
              gradientTranstionClassnames,
              isShadowEnabled ? 'opacity-100' : 'opacity-0',
            )}
          />
          <div
            className={cn(
              'absolute -z-10 aspect-[2] rounded-full bg-primaryOceanBlue',
              'bottom-[56%] right-[-15%] w-[30%] desktop-sm:bottom-[68%]',
              'blur-[20px] tablet-sm:blur-[60px] desktop-sm:blur-[100px]',
              gradientTranstionClassnames,
              isShadowEnabled ? 'opacity-100' : 'opacity-0',
            )}
          />
        </div>
      ) : null}
      <div ref={ref} className="h-full w-full [clip-path:url(#curved-bottom)]">
        {children}
      </div>
    </div>
  );
};

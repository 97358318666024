import React from 'react';

import { useInstantSearch } from 'react-instantsearch';
import { cn } from '@/lib/tailwind';

const INSTANT_SEARCH_LOADING_STATES = ['loading', 'stalled'];

const NoResultsBoundary = ({
  children,
  fallback,
  loader,
}: {
  children: React.ReactNode;
  fallback: React.ReactNode;
  loader?: React.ReactNode;
}) => {
  const { results, status } = useInstantSearch();
  const showLoader =
    INSTANT_SEARCH_LOADING_STATES.includes(status) && Boolean(loader);

  const showFallbackUI =
    !showLoader && !results.__isArtificial && results.nbHits === 0;
  // The `__isArtificial` flag makes sure not to display the No Results message
  // when no hits have been returned yet.

  return (
    <React.Fragment>
      <div className={cn(showLoader ? 'block' : 'hidden')}>{loader}</div>
      <div className={cn(showFallbackUI ? 'block' : 'hidden')}>{fallback}</div>
      <div className={cn(showFallbackUI || showLoader ? 'hidden' : 'block')}>
        {children}
      </div>
    </React.Fragment>
  );
};

export default NoResultsBoundary;

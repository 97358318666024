'use client';

import * as React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { cn } from '@/lib/tailwind';
import { useIsTablet } from '@/hooks/useIsTablet';
import { TooltipTriggerContext } from './tooltip-trigger-context';

const TooltipProvider = TooltipPrimitive.Provider;

const TooltipTrigger = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Trigger>
>(({ children, ...props }, ref) => {
  const isTablet = useIsTablet();

  const { setOpen } = React.useContext(TooltipTriggerContext);

  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      isTablet && e.preventDefault();
      setOpen(true);
    },
    [isTablet, setOpen],
  );

  return (
    <TooltipPrimitive.Trigger ref={ref} {...props} onClick={handleClick}>
      {children}
    </TooltipPrimitive.Trigger>
  );
});

TooltipTrigger.displayName = TooltipPrimitive.Trigger.displayName;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      'data-[state=open]:opacity-100 data-[state=closed]:opacity-0',
      className,
    )}
    {...props}
  />
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

const Tooltip: React.FC<TooltipPrimitive.TooltipProps> = ({
  children,
  ...props
}) => {
  const [open, setOpen] = React.useState<boolean>(props.defaultOpen ?? false);

  // we only want to enable the "click to open" functionality on mobile
  const isTablet = useIsTablet();

  const handleOpen = React.useCallback(
    (flag: boolean) => {
      setOpen(flag);
    },
    [setOpen],
  );

  return (
    <TooltipPrimitive.Root
      delayDuration={isTablet ? 0 : props.delayDuration}
      onOpenChange={handleOpen}
      open={open}
    >
      <TooltipTriggerContext.Provider value={{ open, setOpen }}>
        {children}
      </TooltipTriggerContext.Provider>
    </TooltipPrimitive.Root>
  );
};

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider };

'use client';

import React, { useContext } from 'react';
import { cn } from '@/lib/tailwind';
import { PageContext } from '@/contexts/PageContext';
import { joinPathSegments } from '@/utils/pathSegments';
import { DEFAULT_LANGUAGE_CONFIG } from '@/constants/locales';
import Icon from '../Icon';
import { Link } from '../Link';
import { Crumb } from './types';

const BreadcrumbItem = ({ label, link, icon }: Crumb) => {
  const { locale } = useContext(PageContext);
  const content = (
    <span
      className={cn(
        'text-tablet-sm/overline-3 desktop-lg:text-desktop-large/overline-2 uppercase',
        'inline-flex items-center gap-2 -translate-y-[0.5px]',
        link ? 'group-hover:text-primaryOceanBlue' : null,
      )}
    >
      {icon ? (
        <Icon
          icon="home"
          className={cn(
            'h-2.5 inline-block',
            link ? 'group-hover:fill-primaryOceanBlue' : null,
          )}
        />
      ) : null}
      <span>{label}</span>
    </span>
  );

  if (!link) {
    return content;
  }

  const localisedLink =
    locale === DEFAULT_LANGUAGE_CONFIG.prefixSlug
      ? link
      : joinPathSegments(locale, link);

  return (
    <Link href={localisedLink} className="group block">
      {content}
    </Link>
  );
};

export default BreadcrumbItem;

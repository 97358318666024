import { useMedia } from 'react-use';
import { BREAKPOINTS_IN_NUMBER } from '@/constants/breakpoints';

export const useIsTablet = () => {
  const isTablet = useMedia(
    `(min-width: ${BREAKPOINTS_IN_NUMBER['tablet-sm']}px) and (max-width: ${
      BREAKPOINTS_IN_NUMBER['desktop-sm'] - 1
    }px)`,
    false,
  );

  return isTablet;
};

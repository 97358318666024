'use client';

import { useTranslations } from 'next-intl';
import { useMemo } from 'react';
import Breadcrumb from '@/components/breadcrumb';
import { Crumb } from '@/components/breadcrumb/types';
import { usePageContext } from '@/hooks/usePageContext';

const HOME_LABEL = 'Home';
const CUSTOMER_STORIES_LABEL = 'Customer Stories';

export const Breadcrumbs = () => {
  const { title } = usePageContext();

  const t = useTranslations();

  const crumbs: Crumb[] = useMemo(
    () => [
      {
        label: t(HOME_LABEL),
        link: '/',
        icon: 'home',
      },
      {
        label: t(CUSTOMER_STORIES_LABEL),
        link: '/stories',
      },
      {
        label: title ?? '',
      },
    ],
    [t, title],
  );

  return (
    <Breadcrumb
      items={crumbs}
      className="w-fit desktop-sm:mb-2 desktop-lg:mb-6"
    />
  );
};

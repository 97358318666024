'use client';
import React, { Children, isValidElement, ReactElement, useMemo } from 'react';
import { useBlogListingContext } from '../context/BlogListingContextProvider';
import placeBanners from '../utils/placeBanner';

type Props = {
  categorySectionChildrenList: ReactElement[];
  bannerChildrenList: ReactElement[];
};

const CategorySectionContainer = ({
  categorySectionChildrenList,
  bannerChildrenList,
}: Props) => {
  const { activeCategories } = useBlogListingContext();

  const filteredChildren = useMemo(
    () =>
      Children.toArray(categorySectionChildrenList).filter(
        child =>
          isValidElement(child) &&
          activeCategories.includes(child.props['data-category']),
      ) as ReactElement[],
    [categorySectionChildrenList, activeCategories],
  );

  const childrenWithBanners = useMemo(
    () => placeBanners(filteredChildren, bannerChildrenList),
    [filteredChildren, bannerChildrenList],
  );

  return (
    <div className="flex flex-col gap-y-8 tablet-sm:gap-y-14 desktop-sm:gap-y-10 desktop-lg:gap-y-14">
      {childrenWithBanners}
    </div>
  );
};

export default CategorySectionContainer;

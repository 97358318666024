'use client';
import { PropsWithChildren, createContext, useState } from 'react';

type BodyDimensionsState = {
  height: number | null;
  top: number | null;
};

const BodyDimensionsContext = createContext<
  [BodyDimensionsState, (value: BodyDimensionsState) => void]
>([{ height: null, top: null }, () => {}]);

export const BodyDimensionsContextProvider = ({
  children,
}: PropsWithChildren) => {
  const state = useState<BodyDimensionsState>({
    height: null,
    top: null,
  });
  return (
    <BodyDimensionsContext.Provider value={state}>
      {children}
    </BodyDimensionsContext.Provider>
  );
};

export default BodyDimensionsContext;

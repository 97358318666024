'use client';
import React from 'react';
import { InstantSearch } from 'react-instantsearch';
import algoliasearch from 'algoliasearch';
import { cn } from '@/lib/tailwind';
import MaxWidthContainer from '@/components/MaxWidthContainer';
import Text from '@/components/text';
import Conditional from '@/components/Conditional';
import SearchBar from './components/SearchBox';
import FilterSection from './components/FilterSection';
import LibraryCardGrid from './components/LibraryCardGrid';
import { Props } from './types';
import { SEARCH_GRID_ELEMENT_ID } from './constants';

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_GATSBY_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_GATSBY_ALGOLIA_SEARCH_KEY,
);

const SearchableGrid = ({
  title,
  algoliaIndexName,
  enableDateFiltering = false,
  searchFallbackContent,
}: Props) => {
  return (
    <MaxWidthContainer>
      <Conditional renderIf={title}>
        <Text
          as="p"
          className={cn(
            'text-mobile-sm/heading-5 tablet-sm:text-tablet-sm/heading-4 desktop-sm:text-tablet-sm/heading-3 desktop-lg:text-desktop-large/heading-3',
            'mb-5 tablet-sm:mb-4 desktop-sm:mb-6 desktop-lg:mb-8',
          )}
        >
          {title}
        </Text>
      </Conditional>
      <InstantSearch searchClient={searchClient} indexName={algoliaIndexName}>
        <SearchBar />
        <div
          id={SEARCH_GRID_ELEMENT_ID}
          className="flex flex-col desktop-sm:flex-row gap-10 desktop-sm:gap-x-10 desktop-lg:gap-x-32"
        >
          <FilterSection enableDateFiltering={enableDateFiltering} />
          <LibraryCardGrid
            enableDateFiltering={enableDateFiltering}
            searchFallbackContent={searchFallbackContent}
          />
        </div>
      </InstantSearch>
    </MaxWidthContainer>
  );
};

export default SearchableGrid;
